import { initAccordion } from '../components/initAccordion';
import { initAutocompleteDevices } from '../components/initAutocompleteDevices';
import { initTabs } from '../components/initTabs';
import { initDeclineModal } from '../components/modal/initDeclineModal';
import { initEmailAutocompleteDropdown } from '../../../../../Common/Resources/templates/components/EmailAutocompleteDropdown/initEmailAutocompleteDropdown';
import { initCreateAccountEmail } from '../components/forms/initCreateAccountEmail';
import { initDefaultGlideSlider } from '../components/sliders/initDefaultGlideSlider';
import { initFreeRegFeaturesSlider } from '../components/sliders/initFreeRegFeaturesSlider';

document.addEventListener('DOMContentLoaded', function () {
    initAccordion();
    initCreateAccountEmail();
    initDefaultGlideSlider();
    initFreeRegFeaturesSlider();
    initAutocompleteDevices();
    initTabs();
    initDeclineModal();
    initEmailAutocompleteDropdown();
});
